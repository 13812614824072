import { ToastContainer } from '@iguanads/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'

import { queryClient } from '@/lib/react-query'

import { AudioProvider } from './AudioContext'
import { AuthProvider } from './AuthContext'
import { LiveOrdersProvider } from './LiveOrdersContext'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AudioProvider>
          <LiveOrdersProvider>
            {children}

            <ToastContainer />
          </LiveOrdersProvider>
        </AudioProvider>
      </AuthProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
