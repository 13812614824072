import * as Sentry from '@sentry/browser'
import { Helmet } from 'react-helmet-async'
import { useRouteError } from 'react-router-dom'

import ErrorPage from '@/components/ErrorPage'

export function Error() {
  const error = useRouteError() as Error
  Sentry.captureException(error)

  return (
    <>
      <Helmet title="Error" />
      <ErrorPage error={error.message} />
    </>
  )
}
