/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@iguanads/react'
import axios from 'axios'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import _ from 'lodash'
import { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { OrderStatusEnum } from '@/enums/order.enum'
import { env } from '@/env'
import useAudio from '@/hooks/useAudio'
import useAuth from '@/hooks/useAuth'
import { api } from '@/lib/axios'
import { db } from '@/lib/firebase'
import { OrderType } from '@/types/orders.types'

type LiveOrdersContextProps = {
  orders: OrderType[]
  ongoing: OrderType[]
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

export const LiveOrdersContext = createContext<LiveOrdersContextProps>(
  {} as LiveOrdersContextProps,
)

type LiveOrdersProviderProps = {
  children: React.ReactNode
}

const status = [
  OrderStatusEnum.CREATED,
  OrderStatusEnum.IN_PROGRESS,
  OrderStatusEnum.READY,
  OrderStatusEnum.IN_TRANSIT,
  OrderStatusEnum.SCHEDULED,
]

export function LiveOrdersProvider({ children }: LiveOrdersProviderProps) {
  const [orders, setOrders] = useState<OrderType[]>([])
  const [ongoing, setOngoing] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { merchantsSelected, isSuperAdmin } = useAuth()
  let dataOrders: OrderType[] = []
  const theme = useTheme()
  const { play, playing, canPlay, pause } = useAudio()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isShowingNewOrder, setIsShowingNewOrder] = useState(false)

  useEffect(() => {
    if (isSuperAdmin) return

    if (orders.length > 0) {
      const isNonOrderPage = pathname !== '/' && pathname !== '/dashboard'
      const hasNewOrders = orders.some(
        (order) => order.lastStatus === 'created',
      )

      if (!hasNewOrders && playing) {
        pause()
      }

      if (hasNewOrders && canPlay && isNonOrderPage && !isShowingNewOrder) {
        play()
        setIsShowingNewOrder(true)

        toast(
          {
            type: 'info',
            title: 'New order!',
            message: 'Go to Live tab to accept this order.',
            hideCloseButton: true,
            actionButton: true,
            actionButtonText: 'Go to live orders',
            onClickActionButton: () => {
              setIsShowingNewOrder(false)
              navigate('/')
            },
          },
          {
            onClose: () => {
              setIsShowingNewOrder(false)
              pause()
            },
            autoClose: 600000,
          },
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoing])

  const totalMerchantVouchers = (order: OrderType) => {
    const magicVouchers = order.magicVouchers
    const vouchers = magicVouchers?.map((voucher) => {
      if (voucher.author !== 'bento' && voucher.author === 'merchant') {
        return voucher.balance
      }
      return 0
    })

    if (vouchers && vouchers.length > 0) {
      return _.sum(vouchers) || 0
    }

    return 0
  }

  const getOrders = (merchantId: string) => {
    setOrders([])
    setIsLoading(true)

    const q = query(
      collection(db, 'orders'),
      where('merchant.id', '==', merchantId),
      where('lastStatus', 'in', status),
      orderBy('createdAt', 'desc'),
    )

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const data: OrderType[] = querySnapshot.docs.map(
        (doc: any) => doc.data() as OrderType,
      )

      const hasOrder = data.some((item) => {
        return dataOrders.find((order) => order.orderId === item.orderId)
      })

      if (hasOrder) {
        dataOrders = dataOrders.map((item) => {
          const order = data.find((order) => order.orderId === item.orderId)

          if (order) {
            return order
          }

          return item
        })
      } else {
        dataOrders = [...dataOrders, ...data]
      }

      const countData = data.length
      const filterOrdersByMerchant = dataOrders.filter((order) => {
        return order.merchant.id === merchantId
      })
      const countOrdersByMerchant = filterOrdersByMerchant.length

      if (countData < countOrdersByMerchant) {
        const filteredDataOrders = dataOrders.filter((item) => {
          return item.merchant.id !== merchantId
        })

        dataOrders = [...filteredDataOrders, ...data]
      }

      const allOrdersHasOrderId = dataOrders.every((order) => order.orderId)

      if (countData > countOrdersByMerchant || !allOrdersHasOrderId) {
        const filterOrdersWithoutMerchant = dataOrders.filter((order) => {
          return order.merchant.id !== merchantId
        })

        dataOrders = [...filterOrdersWithoutMerchant, ...data]
      }

      async function getTripByOrderId(orderId: string) {
        try {
          const { data } = await axios.get(
            `${env.VITE_APP_API_URL}/orders/${orderId}/trip`,
            {
              headers: {
                Authorization: api.defaults.headers.Authorization,
              },
            },
          )
          return data
        } catch (error) {}
      }

      const ordersWithTrip = await Promise.all(
        _.orderBy(dataOrders, 'createdAt', 'desc').map(
          async (item: OrderType) => {
            const trip = item.trip ? await getTripByOrderId(item.orderId) : null

            return {
              ...item,
              theme,
              trip,
              total:
                ((item.deliveryHandler !== 'bento'
                  ? item.total
                  : item.subTotal) -
                  totalMerchantVouchers(item)) /
                100,
              subTotal: item.subTotal / 100,
              deliveryFee: item.deliveryFee ? item.deliveryFee / 100 : null,
              tip: item.tip
                ? {
                    orderId: item.shortId,
                    amount: item.tip.amount / 100,
                  }
                : null,
              magicVouchers: item.magicVouchers
                ? item.magicVouchers.map((voucher) => ({
                    ...voucher,
                    balance: voucher?.balance / 100,
                  }))
                : [],
              refundAmount: item.refundAmount ? item.refundAmount / 100 : null,
              items: item.items?.map((child) => ({
                ...child,
                price: child.price / 100,
                modifiers: child.modifiers?.map((modifier) => ({
                  ...modifier,
                  options: modifier.options?.map((option) => ({
                    ...option,
                    price: option.price / 100,
                  })),
                })),
              })),
            }
          },
        ),
      )

      setOrders(ordersWithTrip)

      setIsLoading(false)
    })

    return unsubscribe
  }

  useEffect(() => {
    const unsubscribe: Array<() => void> = []

    if (merchantsSelected) {
      const merchants = merchantsSelected.map((item) => item.id)
      merchants.forEach((merchantId) => {
        if (!isLoading) {
          const unsub = getOrders(merchantId)
          unsubscribe.push(unsub)
        }
      })
    }

    return () => {
      unsubscribe.forEach((unsub) => {
        if (typeof unsub === 'function') {
          unsub()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(merchantsSelected)])

  useEffect(() => {
    setOngoing(
      orders.filter(
        (order) =>
          order.lastStatus === 'created' ||
          order.lastStatus === 'in_progress' ||
          order.lastStatus === 'ready' ||
          order.lastStatus === 'in_transit' ||
          order.lastStatus === 'scheduled',
      ),
    )
  }, [orders])

  return (
    <LiveOrdersContext.Provider
      value={{ orders, ongoing, isLoading, setIsLoading }}
    >
      {children}
    </LiveOrdersContext.Provider>
  )
}
