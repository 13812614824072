import { z } from 'zod'

const envSchema = z.object({
  MODE: z.enum(['development', 'production', 'test']),
  VITE_ENV: z.string().default('stage'),
  VITE_API_KEY: z.string(),
  VITE_AUTH_DOMAIN: z.string(),
  VITE_DATABASE_URL: z.string().url(),
  VITE_PROJECT_ID: z.string(),
  VITE_STORAGE_BUCKET: z.string(),
  VITE_MESSAGING_SENDER_ID: z.string(),
  VITE_APP_ID: z.string(),
  VITE_MEASUREMENT_ID: z.string(),
  VITE_API_URL: z.string().url(),
  VITE_APP_API_URL: z.string().url(),
  VITE_SERVICES_API_URL: z.string().url(),
  VITE_MAPS_API_KEY: z.string(),
  VITE_VAPID_KEY: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_SENTRY_AUTH_TOKEN: z.string(),
  VITE_STAGE_SENTRY_DSN: z.string(),
})

export const env = envSchema.parse(import.meta.env)
